export default {
  England: {
    name: 'England',
    counties: {
      Derbyshire: {
        name: 'Derbyshire',
        districts: [
          'Alfreton And District',
          'Belper',
          'Chesterfield',
          'Derby East',
          'Derby North',
          'Derby South',
          'Derwent and Dove',
          'Dronfield',
          'East Scarsdale',
          'Erewash',
          'High Peak',
          'Peak',
          'Ripley And Heanor',
          'South Derbyshire',
        ],
      },
      Leicestershire: {
        name: 'Leicestershire',
        districts: [
          'Ashby And Coalville',
          'Charnwood',
          'Harborough',
          'Hinckley',
          'Leicester North East',
          'Leicester South East',
          'Leicester South West',
          'Loughborough',
          'Melton Mowbray And District',
          'South Leicestershire',
        ],
      },
      Lincolnshire: {
        name: 'Lincolnshire',
        districts: [
          'Boston',
          'Directly Administered District - Lincolnshire Traveller Initiative',
          'Gainsborough',
          'Grantham',
          'Lincoln',
          'Louth',
          'Rutland',
          'Skegness Spilsby And District',
          'Sleaford',
          'South Holland',
          'Stamford And Bourne',
        ],
      },
      Northamptonshire: {
        name: 'Northamptonshire',
        districts: [
          'Daventry And District',
          'Glendon',
          'Grafton',
          'Nene Valley',
          'Northampton',
          'Wellingborough',
        ],
      },
      Nottinghamshire: {
        name: 'Nottinghamshire',
        districts: [
          'Ashfield',
          'Bassetlaw',
          'Beauvale',
          'Central Notts',
          'City Of Nottingham',
          'Mansfield',
          'Newark And District',
          'Rushcliffe',
          'South West Notts',
        ],
      },
      Bedfordshire: {
        name: 'Bedfordshire',
        districts: [
          'Bedford and Ouse Valley',
          'Biggleswade',
          'Dunstable',
          'Falkes',
          'Icknield (Luton)',
          'Leighton-Linslade',
        ],
      },
      Cambridgeshire: {
        name: 'Cambridgeshire',
        districts: [
          'Cambridge',
          'Cambridge Crafts Hill',
          'Cromwell',
          'Ely',
          'Fenland',
          'Hinchingbrooke',
          'Newmarket',
          'Peterborough District',
        ],
      },
      Essex: {
        name: 'Essex',
        districts: [
          'Basildon',
          'Billericay & Wickford',
          'Braintree',
          'Brentwood And District',
          'Castle Point',
          'Chelmsford',
          'Colchester Estuary',
          'Colchester North',
          'Crouch Valley',
          'Harlow And District',
          'Hatfield Forest',
          'Maldon and East Essex',
          'Royal Forest',
          'Saffron Walden',
          'Southend Estuary',
          'Southend West',
          'Tendring',
          'Thurrock',
        ],
      },
      Hertfordshire: {
        name: 'Hertfordshire',
        districts: [
          'Bishops Stortford And District',
          'East Herts',
          'Elstree and District',
          'Harpenden And Wheathampstead',
          'Hemel Hempstead',
          'Hertford',
          'Hitchin And District',
          'Letchworth And Baldock',
          'Mid Herts',
          'Potters Bar And District',
          'Rickmansworth And Chorleywood',
          'Royston',
          'St Albans',
          'Stevenage',
          'Ware And District',
          'Watford North',
          'Watford South',
          'West Herts',
        ],
      },
      Norfolk: {
        name: 'Norfolk',
        districts: [
          'Central Norfolk',
          'East Norfolk',
          'Eastern Norwich',
          'North East Norfolk',
          'North West Norfolk',
          'Northern Norwich',
          'Southern Norfolk',
          'Southern Norwich',
        ],
      },
      Suffolk: {
        name: 'Suffolk',
        districts: [
          'Bury St Edmunds',
          'Deben',
          'Lowestoft',
          'Orwell',
          'Stowmarket',
          'Sudbury',
          'Waveney Valley',
          'Wolsey',
        ],
      },
      'Gt. London Middlesex West': {
        name: 'Gt. London Middlesex West',
        districts: [
          'Brent',
          'Ealing & Hanwell',
          'Greenford & District',
          'Harrow',
          'Hillingdon West',
          'Ruislip Eastcote Northwood',
          'Thameside Grand Union',
          'West London',
        ],
      },
      'Gt. London North': {
        name: 'Gt. London North',
        districts: ['Barnet Borough', 'Enfield', 'North London', 'Southgate'],
      },
      'Gt. London North East': {
        name: 'Gt. London North East',
        districts: [
          'Barking, Dagenham & Newham',
          'East London',
          'Hornchurch',
          'Redbridge',
          'Romford',
          'Waltham Forest',
        ],
      },
      'Gt. London South': {
        name: 'Gt. London South',
        districts: [
          'Bexley',
          'Bromley',
          'Lambeth',
          'Lewisham District',
          'Royal Greenwich District',
          'Southwark District',
          'Wandsworth',
        ],
      },
      'Gt. London South West': {
        name: 'Gt. London South West',
        districts: [
          'Croydon',
          'Richmond upon Thames',
          'Royal Kingston',
          'Sutton',
          'Wimbledon and Wandle',
        ],
      },
      'Central Yorkshire': {
        name: 'Central Yorkshire',
        districts: [
          'Central Yorkshire Directly Administered District',
          'Leeds Templars',
          'North Leeds',
          'Pontefract & Castleford',
          'Shire Oak (Leeds)',
          'South Leeds & Morley',
          'Wakefield',
          'West Leeds',
          'Wetherby',
        ],
      },
      Cleveland: {
        name: 'Cleveland',
        districts: [
          'East Cleveland',
          'Middlesbrough',
          'Redcar And Eston',
          'Stockton Thornaby And District',
          'Tees Valley North',
        ],
      },
      Durham: {
        name: 'Durham',
        districts: [
          'Bishop Auckland',
          'Blaydon And District',
          'Chester-le-street',
          'Crook And Weardale',
          'Darlington And District',
          'Derwentside',
          'Durham City And District',
          'Gateshead And District',
          'Houghton-le-Spring',
          'Peterlee',
          'Seaham And District',
          'South Tyneside',
          'Sunderland',
          'Teesdale',
        ],
      },
      Humberside: {
        name: 'Humberside',
        districts: [
          'Beverley And Hornsea',
          'Blacktoft Beacon',
          'City of Hull',
          'Grimsby And Cleethorpes',
          'North Lincolnshire',
          'Pocklington',
          'South Holderness',
          'Wolds & Coast',
        ],
      },
      'North Yorkshire': {
        name: 'North Yorkshire',
        districts: [
          'Galtres',
          'Harrogate And Nidderdale',
          'Ingleborough',
          'North Hambleton',
          'Richmondshire',
          'Ripon And District',
          'Ryedale',
          'Scarborough And District',
          'Selby',
          'South Craven',
          'York Ebor',
          'York Minster',
        ],
      },
      Northumberland: {
        name: 'Northumberland',
        districts: [
          'Benton',
          'Blyth Valley',
          'Castle Morpeth',
          'City of Newcastle',
          'Hadrian',
          'Mid Northumberland',
          'North Northumberland',
          'Tynemouth',
          'Wansbeck',
          'Whitley Bay And District',
        ],
      },
      'South Yorkshire': {
        name: 'South Yorkshire',
        districts: [
          'Barnsley',
          'Doncaster Danum',
          'Rotherham',
          'Sheffield Don',
          'Sheffield Hallam',
          'Sheffield Norfolk',
          'Sheffield Sheaf',
        ],
      },
      'West Yorkshire': {
        name: 'West Yorkshire',
        districts: [
          'Aire Valley',
          'Bradford North',
          'Bradford South',
          'Brighouse',
          'Halifax',
          'Heavy Woollen',
          'Holme Valley',
          'Huddersfield North',
          'Huddersfield South East',
          'Huddersfield South West',
          'Keighley',
          'Pennine Calder',
          'Spen Valley',
          'Wharfedale',
        ],
      },
      Cheshire: {
        name: 'Cheshire',
        districts: [
          'Alderley and Knutsford District',
          'Chester And District',
          'Ellesmere Port And Neston Scout District',
          'Macclesfield And Congleton',
          'Mersey Weaver',
          'Mid Cheshire',
          'South West Cheshire',
          'Warrington East',
          'Warrington West',
        ],
      },
      Cumbria: {
        name: 'Cumbria',
        districts: [
          'Eden',
          'Kentdale',
          'North Fells',
          'Reivers',
          'South West Lakes',
          'Western Lakes',
        ],
      },
      'East Lancashire': {
        name: 'East Lancashire',
        districts: [
          'Blackburn',
          'Burnley and Pendle',
          'Clitheroe And District',
          'Darwen',
          'Hyndburn',
          'Rossendale Valley',
        ],
      },
      'Gt. Manchester East': {
        name: 'Gt. Manchester East',
        districts: [
          'Cheadle And District',
          'Glossop',
          'Ladybrook Valley',
          'Manchester South',
          'North Manchester',
          'North Tameside',
          'Stockport',
          'Tame Valley',
          'Tameside South',
        ],
      },
      'Gt. Manchester North': {
        name: 'Gt. Manchester North',
        districts: [
          'Bolton Moorland',
          'Bolton South with Farnworth',
          'Bury and Ramsbottom',
          'Oldham Borough',
          'Pennine',
          'Prestwich, Radcliffe & Whitefield',
        ],
      },
      'Gt. Manchester West': {
        name: 'Gt. Manchester West',
        districts: [
          'Altrincham And District',
          'Ellesmere',
          'Lilford',
          'Lowry',
          'Sale And District',
          'Stretford',
          'Urmston And District',
          'Wigan And District',
        ],
      },
      'Isle Of Man': {
        name: 'Isle Of Man',
        districts: ['Isle Of Man'],
      },
      Merseyside: {
        name: 'Merseyside',
        districts: [
          'Altside',
          'Bebington',
          'Birkenhead',
          'Crosby',
          'Liverpool North',
          'Liverpool South',
          'Sefton North',
          'St Helens And District',
          'Wallasey',
          'West Wirral',
        ],
      },
      'West Lancashire': {
        name: 'West Lancashire',
        districts: [
          'Blackpool And District',
          'Chorley',
          'Fylde',
          'Lonsdale',
          'Ormskirk And District',
          'Preston And District',
          'South Ribble',
          'Wyre',
        ],
      },
      Buckinghamshire: {
        name: 'Buckinghamshire',
        districts: [
          'Aylesbury',
          'Buckingham Winslow And District',
          'Chiltern North',
          'Chiltern Vale',
          'High Wycombe',
          'Marlow',
          'Milton Keynes',
          'Misbourne Valley',
          'Vale Of Aylesbury',
          'Woodlands',
        ],
      },
      'East Sussex': {
        name: 'East Sussex',
        districts: [
          'Ashdown',
          'Brighton & Hove',
          'Eastbourne',
          'Hailsham And Heathfield',
          'Hastings Rye And District',
          'Lewes',
          'Seahaven',
          'Senlac',
        ],
      },
      Hampshire: {
        name: 'Hampshire',
        districts: [
          'Andover',
          'Arrow District',
          'Basingstoke East',
          'Basingstoke West',
          'Blackwater Valley',
          'Bramshill',
          'City of Portsmouth',
          'Denewulf',
          'Fareham West',
          'Gosport',
          'Hawk District',
          'Itchen North',
          'Itchen South',
          'New Forest',
          'New Forest Solent',
          'Odiham',
          'Petersfield',
          'Romsey',
          'Rotherfield',
          'Southampton City',
          'Winchester',
        ],
      },
      'Isle Of Wight': {
        name: 'Isle Of Wight',
        districts: ['Isle of Wight District'],
      },
      Kent: {
        name: 'Kent',
        districts: [
          'Ashford',
          'Canterbury Whitstable & Herne Bay',
          'Dartford',
          'Deal Walmer and Sandwich',
          'Directly Administered District',
          'Dover',
          'Faversham',
          'Folkestone And Hythe',
          'Gillingham',
          'Gravesham',
          'Isle Of Sheppey',
          'Maidstone East',
          'Maidstone West',
          'Malling',
          'Medway Towns',
          'Romney Marsh',
          'Royal Tunbridge Wells',
          'Sevenoaks',
          'Sittingbourne, Milton & District',
          'Swanley',
          'Thanet',
          'Tonbridge',
          'Weald',
        ],
      },
      Oxfordshire: {
        name: 'Oxfordshire',
        districts: [
          'King Alfred',
          'North Oxfordshire',
          'Oxford Spires',
          'Thames Chiltern',
          'Thames Ridge',
          'West Oxfordshire',
        ],
      },
      'Royal Berkshire': {
        name: 'Royal Berkshire',
        districts: [
          'Kennet',
          'Loddon',
          'Maidenhead',
          'Pang Valley',
          'Reading Central',
          'Slough',
          'South Berkshire',
          'South East Berkshire',
          'Taceham Hundred',
          'Windsor And Eton',
          'Wokingham',
        ],
      },
      Surrey: {
        name: 'Surrey',
        districts: [
          'Banstead',
          'Caterham',
          'Dorking',
          'East Surrey',
          'Epsom And Ewell',
          'Esher',
          'Farnham',
          'Godalming',
          'Guildford East',
          'Guildford West',
          'Haslemere And District',
          'Leatherhead',
          'Reigate',
          'Runnymede',
          'Spelthorne',
          'Surrey Heath',
          'Walton And Weybridge',
          'Woking',
        ],
      },
      'West Sussex': {
        name: 'West Sussex',
        districts: [
          'Adur Valley',
          'Arundel And Littlehampton',
          'Bognor Regis And District',
          'Chichester',
          'Crawley',
          'East Grinstead',
          'Horsham',
          'Mid Sussex',
          'Petworth And Pulborough',
          'Worthing',
        ],
      },
      Avon: {
        name: 'Avon',
        districts: [
          'Axe',
          'Bristol South',
          'Brunel',
          'Cabot',
          'City Of Bath',
          'Cotswold Edge',
          'Gordano',
          'Kingswood',
          'Wansdyke',
        ],
      },
      'Bailiwick Of Guernsey': {
        name: 'Bailiwick Of Guernsey',
        districts: ['Bailiwick Of Guernsey'],
      },
      Cornwall: {
        name: 'Cornwall',
        districts: [
          'Carrick',
          'East Cornwall',
          'Mid Cornwall',
          'North Cornwall',
          'Penwith And Isles Of Scilly',
          "Stenek Ha'n Mor",
        ],
      },
      Devon: {
        name: 'Devon',
        districts: [
          'East Devon',
          'Exeter',
          'Exmouth And Budleigh Salterton',
          'Mid Devon',
          'North Devon',
          'Plymouth',
          'South Devon',
          'Teignbridge',
          'Tiverton',
          'Torbay District',
          'West Devon',
        ],
      },
      Dorset: {
        name: 'Dorset',
        districts: [
          'Bournemouth',
          'Christchurch',
          'Dorchester and West Dorset',
          'East Dorset',
          'North Dorset',
          'Poole',
          'Wareham And Isle Of Purbeck',
          'Weymouth And Portland',
        ],
      },
      Gloucestershire: {
        name: 'Gloucestershire',
        districts: [
          'Cheltenham',
          'Cotswold',
          'Cotswold Vale',
          'Forest Of Dean',
          'Gloucester',
          'North Cotswold',
          'Park',
          'Stroud And Tetbury',
          'Tewkesbury',
        ],
      },
      Jersey: {
        name: 'Jersey',
        districts: ['Jersey'],
      },
      Somerset: {
        name: 'Somerset',
        districts: ['Blackdown', 'East Somerset', 'Moors and Coastal'],
      },
      Wiltshire: {
        name: 'Wiltshire',
        districts: [
          'Mid Wiltshire',
          'North East Wiltshire',
          'Salisbury And South Wilts',
          'Swindon North',
          'Swindon Ridgeway',
          'Wiltshire North',
          'Wiltshire West',
        ],
      },
      Birmingham: {
        name: 'Birmingham',
        districts: [
          'Birmingham Spitfire District Scout Association',
          'Cole Valley South',
          'Rea Valley',
          'Sutton Coldfield East',
          'Sutton Coldfield West',
          'Tame Valley Birmingham',
        ],
      },
      'City Of Coventry': {
        name: 'City Of Coventry',
        districts: ['Bablake', 'Caludon', 'Charterhouse'],
      },
      'Hereford & Worcester': {
        name: 'Hereford & Worcester',
        districts: [
          'Avon Vale',
          'Bromsgrove',
          'Redditch',
          'Ross On Wye',
          'South Marches',
          'The Malverns',
          'The Shire',
          'Worcester',
          'Wyre Forest',
        ],
      },
      Shropshire: {
        name: 'Shropshire',
        districts: [
          'Shropshire Borders',
          'Shropshire Severn And Teme',
          'Tern Valley',
        ],
      },
      Solihull: {
        name: 'Solihull',
        districts: ['Blythe', 'Cole'],
      },
      Staffordshire: {
        name: 'Staffordshire',
        districts: [
          'Burton Upon Trent',
          'Cannock',
          'Lichfield',
          'Moorlands',
          'Newcastle',
          'Potteries North',
          'Stafford',
          'Stone And District',
          'Tamworth',
          'Three Towns',
        ],
      },
      Warwickshire: {
        name: 'Warwickshire',
        districts: [
          'Atherstone',
          'Kenilworth',
          'Leamington',
          'Nuneaton',
          'Rugby',
          'Stratford Upon Avon',
          'Warwick',
          'West Warwickshire',
        ],
      },
      'West Mercia': {
        name: 'West Mercia',
        districts: [
          'Dudley',
          'Halesowen',
          'Sandwell',
          'Stourbridge',
          'Walsall',
          'Walsall North',
          'Wolverhampton East',
          'Wolverhampton North',
          'Wolverhampton South',
        ],
      },
    },
  },
  'Northern Ireland': {
    name: 'Northern Ireland',
    counties: {
      East: {
        name: 'East',
        districts: ['East Belfast', 'North Down', 'Strangford'],
      },
      North: {
        name: 'North',
        districts: [
          'North Belfast',
          'North West Belfast',
          'Slemish District',
          'South East Antrim',
        ],
      },
      South: {
        name: 'South',
        districts: [
          'Armagh',
          'Lagan District',
          'Lisburn District',
          'South Down',
        ],
      },
      West: {
        name: 'West',
        districts: ['Fermanagh', 'Londonderry', 'Tyrone'],
      },
    },
  },
  Overseas: {
    name: 'Overseas',
    counties: {
      Anguilla: {
        name: 'Anguilla',
        districts: ['Anguilla'],
      },
      Bermuda: {
        name: 'Bermuda',
        districts: ['Bermuda'],
      },
      'British Virgin Islands': {
        name: 'British Virgin Islands',
        districts: ['British Virgin Islands'],
      },
      'Cayman Islands': {
        name: 'Cayman Islands',
        districts: ['Cayman Islands'],
      },
      Gibraltar: {
        name: 'Gibraltar',
        districts: ['Gibraltar'],
      },
      'St Kitts and Nevis': {
        name: 'St Kitts and Nevis',
        districts: ['St Kitts and Nevis'],
      },
      Tonga: {
        name: 'Tonga',
        districts: ['Tonga'],
      },
      Tuvalu: {
        name: 'Tuvalu',
        districts: ['Tuvalu'],
      },
      'British Scouting Overseas': {
        name: 'British Scouting Overseas',
        districts: [
          'Middle East',
          'Northern Europe',
          'Rest of the World',
          'Southern Europe',
        ],
      },
    },
  },
  Scotland: {
    name: 'Scotland',
    counties: {
      Clyde: {
        name: 'Clyde',
        districts: [
          'Calder',
          'Clydesdale',
          'Eastwood',
          'Great Western',
          'Kelvin Valley',
          'Regionally Administered (Clyde)',
          'South East',
          'South Western',
          'Strathcalder',
        ],
      },
      East: {
        name: 'East',
        districts: [
          'Arbroath and Montrose',
          'Dundee',
          'Dunfermline',
          'Glenrothes and Levenmouth',
          'Kirkcaldy',
          'North Angus',
          'North East Fife',
          'Perth and Kinross',
          'Rosyth',
          'South Angus',
        ],
      },
      Forth: {
        name: 'Forth',
        districts: [
          'Clackmannanshire',
          'Falkirk',
          'Stirling and Trossachs',
          'West Lothian',
        ],
      },
      'Highlands and Islands': {
        name: 'Highlands and Islands',
        districts: [
          'Caithness and Sutherland',
          'Inverness,Nairn , Badenoch and Strathspey',
          'Lochaber',
          'Orkney',
          'Ross, Skye and Lochalsh',
          'Western Isles',
        ],
      },
      'North East': {
        name: 'North East',
        districts: [
          'Aberdeen',
          'Buchan',
          'Deeside',
          'Deveron',
          'Gordon',
          'Kincardineshire',
          'Moray',
          'Shetland',
        ],
      },
      'South East': {
        name: 'South East',
        districts: [
          'Borders',
          'Braid',
          'Craigalmond',
          'East Lothian',
          'Edinburgh North East',
          'Midlothian',
          'Pentland',
          'Regionally Administered (South East)',
        ],
      },
      'South West': {
        name: 'South West',
        districts: [
          'Ayrshire East',
          'Dumfriesshire',
          'Galloway',
          'Kyle and Carrick',
          'North Ayrshire and Arran',
        ],
      },
      West: {
        name: 'West',
        districts: [
          'Argyll',
          'Dunbartonshire',
          'Greenock and District',
          'Paisley & District',
          'Renfrewshire',
        ],
      },
    },
  },
  Wales: {
    name: 'Wales',
    counties: {
      Clwyd: {
        name: 'Clwyd',
        districts: [
          'Flintshire',
          'Llangollen And District',
          'Vale Of Clwyd (Denbighshire)',
          'Wrexham',
        ],
      },
      'Snowdonia And Anglesey': {
        name: 'Snowdonia And Anglesey',
        districts: [
          'Colwyn Bay & Abergele',
          'Conwy',
          'Gwynedd',
          'Isle Of Anglesey',
        ],
      },
      Brecknock: {
        name: 'Brecknock',
        districts: ['Beacons & Builth', 'Ystradgynlais'],
      },
      Montgomeryshire: {
        name: 'Montgomeryshire',
        districts: ['Montgomeryshire'],
      },
      Radnor: {
        name: 'Radnor',
        districts: ['Radnor'],
      },
      'Cardiff and Vale': {
        name: 'Cardiff and Vale',
        districts: [
          'Cardiff East',
          'Cardiff North',
          'Cardiff West',
          'Directly Administered - Cardiff & the Vale of Glamorgan',
          'Penarth And District',
          'Tir A Môr',
        ],
      },
      'Glamorgan West': {
        name: 'Glamorgan West',
        districts: ['Afan Nedd', 'Cwm Newydd', 'Swansea and Gower'],
      },
      Gwent: {
        name: 'Gwent',
        districts: ['Blaenau Gwent', 'Monmouthshire', 'Newport', 'Torfaen'],
      },
      'Mid Glamorgan': {
        name: 'Mid Glamorgan',
        districts: ['Bridgend', 'CRAI', 'Merthyr Tydfil', 'Rhondda Cynon Taff'],
      },
      Carmarthenshire: {
        name: 'Carmarthenshire',
        districts: ['Carmarthenshire District'],
      },
      Ceredigion: {
        name: 'Ceredigion',
        districts: ['Ceredigion District'],
      },
      Pembrokeshire: {
        name: 'Pembrokeshire',
        districts: ['Pembrokeshire District'],
      },
    },
  },
};
