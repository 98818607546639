import * as T from '../../Typography';
import * as S from './style';
import { format } from 'date-fns';
import IconWithTextButton from '../../IconWithTextButton';

const SuggestedSkill = ({
  learnerName,
  learnerEmail,
  date,
  text,
  agreeToFollowUp,
  skillAreaTitle,
  archived,
  onClickArchive,
  onClickDelete,
}) => {
  return (
    <S.Wrapper>
      {agreeToFollowUp ? (
        <T.Link16
          underline={false}
          href={`mailto:${learnerEmail}`}
          target="_blank"
          color="blue"
          fontSize="18px"
        >
          {learnerName}
        </T.Link16>
      ) : (
        <T.Body16B color="blue" fontSize="18px">
          {learnerName}
        </T.Body16B>
      )}

      <T.BodySM color="gray">
        <bold>Sent:</bold> {format(new Date(date), 'dd MMM, yyyy')}
      </T.BodySM>
      <T.BodySM color="gray">
        <bold>Skill Area:</bold> {skillAreaTitle}
      </T.BodySM>
      <T.Body16 color="gray" mt={2}>
        {text}
      </T.Body16>

      <S.IconsWrapper>
        <IconWithTextButton
          mt={3}
          text={archived ? 'Unarchive' : 'Archive'}
          color="blue"
          icon={archived ? 'archiveUp' : 'archiveDown'}
          iconProps={{ width: 24, height: 24, pointer: true }}
          onClick={onClickArchive}
        />
        {archived && (
          <IconWithTextButton
            text={'Delete'}
            color="red"
            icon={'trash'}
            iconProps={{ width: 24, height: 24, pointer: true }}
            onClick={onClickDelete}
          />
        )}
      </S.IconsWrapper>
    </S.Wrapper>
  );
};

export default SuggestedSkill;
