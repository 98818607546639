import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
const { Paragraph } = Typography;

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacings[4]};
  min-height: ${({ isMobile }) => (isMobile ? '50px' : '175px')};
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray1};
  cursor: pointer;
`;

export const IconContainer = styled.div`
  background-color: ${({ theme, color }) => theme.colors[color]};

  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const Content = styled.div`
  display: flex;
  padding: ${({ theme: { spacings } }) => spacings[3]};
  align-items: center;
  gap: ${({ theme: { spacings } }) => spacings[3]};
  margin: auto 0 0 0;
`;

export const Span = styled.span`
  display: block;
`;

export const Div = styled.div`
  display: flex;
`;

export const BookmarkedWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 20px;
`;

export const ContentWrapper = styled(Link)`
  flex: 1;
  display: flex;
  align-content: space-between;
  flex-direction: column;
`;

export const Title = styled(Paragraph)`
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.2px;
  font-size: 16px;
  margin-top: ${({ theme }) => theme.spacings[3]};
  margin-left: ${({ theme }) => theme.spacings[3]};
  margin-right: ${({ theme }) => theme.spacings[6]};
`;
