import axios from 'axios';
import * as Activities from './activities';
import * as CheckIns from './check-ins';
import * as Organisations from './organisations';
import * as Users from './users';
import * as Skills from './skills';
import * as SkillAreas from './skill-areas';
import * as Volunteers from './volunteers';
import * as Requests from './requests';
import * as DigitalChampions from './digital-champions';
import * as CMS from './cms';
import * as media from './media';
import * as Questions from './questions';

axios.defaults.baseURL = `/api`;

export {
  Activities,
  CheckIns,
  Organisations,
  Users,
  Skills,
  SkillAreas,
  Volunteers,
  Requests,
  DigitalChampions,
  CMS,
  media,
  Questions,
};
