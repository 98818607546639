import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';
import * as CS from '../style';

export const Input = styled.input`
  ${setMargin};
  ${CS.placeholderStyle};
  border-top: ${({ theme, outline }) =>
    outline ? 'none' : theme.borders.primary};
  border-right: ${({ theme, outline }) =>
    outline ? 'none' : theme.borders.primary};
  border-left: ${({ theme, outline }) =>
    outline ? 'none' : theme.borders.primary};
  border-bottom: ${({ theme, outline }) => theme.borders.primary};
  border-color: ${({ theme, color, borderColor }) => {
    if (borderColor) {
      return borderColor
        ? theme.colors[borderColor] || borderColor
        : theme.colors.gray;
    }
    return color ? theme.colors[color] : theme.colors.gray;
  }};
  width: 100%;
  padding: ${({ outline, showSearchIcon }) => {
    if (showSearchIcon) {
      return '1rem 6rem 1rem 1rem';
    } else if (outline) {
      return '1rem 1rem 1rem 0';
    } else {
      return '1rem';
    }
  }};

  ${({ theme }) => theme.media.mobile} {
    padding: ${({ outline, showSearchIcon }) => {
      if (showSearchIcon) {
        return '1rem 4rem 1rem 1rem';
      } else if (outline) {
        return '1rem 1rem 1rem 0';
      } else {
        return '1rem';
      }
    }};
  }

  color: ${({ theme, outline }) =>
    outline ? theme.colors.white : theme.colors.gray};
  font-size: 16px;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  background: ${({ outline }) => outline && 'none'};

  :focus {
    outline: none;
    border-color: ${({ theme, borderColor }) =>
      borderColor
        ? theme.colors[borderColor] || borderColor
        : theme.colors.blue};
  }

  ${({ big }) =>
    big &&
    `
  border: none;
  border-bottom: 1px solid;
  font-size: 23.3px;
  padding-left: 0.25rem;

  :focus {
    border-color: ${({ theme }) => theme.colors.white};
    border-bottom: 3px solid;
  }
  `}
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const IconsWrapper = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ theme }) => theme.media.mobile} {
    right: 10px;
  }
`;

export const SearchButton = styled.button`
  width: 36px;
  height: 36px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.mobile} {
    width: 24px;
    height: 24px;
  }
`;

export const CloseButton = styled.button`
  width: 36px;
  height: 36px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacings[2]};
  ${({ theme }) => theme.media.mobile} {
    width: 24px;
    height: 24px;
  }
`;
