import axios from 'axios';
import handleError from './format-error';

const SKILLS_BASE = '/skills';

const getSkillById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${SKILLS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSkills = async ({ areas, type, options }) => {
  try {
    const { data } = await axios.get(`${SKILLS_BASE}`, {
      params: { areas, type: type },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const bookmarkSkill = async ({ skillId, bookmark, options }) => {
  try {
    const { data } = await axios.patch(`${SKILLS_BASE}/${skillId}/bookmark`, {
      bookmark,
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getHqSkillsProgress = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${SKILLS_BASE}/progress`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const SearchSkillsAndActivities = async ({ task, tool, offset, options }) => {
  try {
    const { data } = await axios.get(`${SKILLS_BASE}/search`, {
      params: { task, tool, offset },
    });
    // if data is empty return empty array
    if (!data.length) return { data: [] };

    // return unique values in data array based on title
    const uniqueData = data.filter(
      (v, i, a) => a.findIndex((t) => t.title === v.title) === i
    );

    return { data: uniqueData };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getUserSkillsStats = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${SKILLS_BASE}/stats`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getMostPopularTasks = async ({ offset, options } = {}) => {
  try {
    const { data } = await axios.get(`${SKILLS_BASE}/popular`, {
      params: { offset },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const editSkill = async ({ id, form, options } = {}) => {
  try {
    const { data } = await axios.patch(`${SKILLS_BASE}/${id}`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createSkill = async ({ form, options } = {}) => {
  try {
    const { data } = await axios.post(`${SKILLS_BASE}`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSuggestedSkills = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${SKILLS_BASE}/suggested`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const suggestSkill = async ({
  text,
  userId,
  skillAreaId,
  agreeToFollowUp,
  options,
}) => {
  try {
    // To be created in backend
    const { data } = await axios.post(`${SKILLS_BASE}/suggested`, {
      text,
      userId,
      skillAreaId,
      agreeToFollowUp,
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateArchiveStateForSuggestedSkill = async ({
  id,
  archived,
  options,
}) => {
  try {
    const { data } = await axios.patch(`${SKILLS_BASE}/suggested/${id}`, {
      archived,
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const deleteSuggestedSkill = async ({ id, options }) => {
  try {
    const { data } = await axios.delete(`${SKILLS_BASE}/suggested/${id}`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteSkill = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.delete(`${SKILLS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getSkillById,
  SearchSkillsAndActivities,
  getMostPopularTasks,
  getUserSkillsStats,
  getHqSkillsProgress,
  editSkill,
  getSkills,
  bookmarkSkill,
  getSuggestedSkills,
  suggestSkill,
  deleteSkill,
  createSkill,
  updateArchiveStateForSuggestedSkill,
  deleteSuggestedSkill,
};
