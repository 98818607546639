import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga4';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './helpers/auth-config';
import { PublicClientApplication, EventType } from '@azure/msal-browser';

import ErrorBoundary from './ErrorBoundary';

if (process.env.NODE_ENV === 'production') {
  // SENTRY
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

  // GOOGLE ANALYTICS
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);

  // pages being tracked by Tag Manager so not needed here
  // ReactGA.pageview(window.location.pathname + window.location.search);
}

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  ReactDOM.render(
    <ErrorBoundary>
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          {/* for accessibility https://www.w3.org/WAI/WCAG21/Techniques/general/G1 */}
          <a href="#main-content" className="skip-link">
            Skip to main content
          </a>

          <App />
        </MsalProvider>
      </React.StrictMode>
    </ErrorBoundary>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
