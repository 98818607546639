import React from 'react';
import { handleClickForAnalytics } from '../../helpers';

import * as S from './style';
import * as T from '../../components/Typography';
import { Grid, Icon } from '../../components';
import InvestorsInPeople from '../../assets/investors-in-people.png';

const { Col, Row } = Grid;

const handleClick = (e) => {
  return handleClickForAnalytics({
    name: 'link_click',
    category: 'link_click',
    action: 'External',
    label: e.target.href,
    priority: 'Low',
  });
};

const linkProps = {
  color: 'white',
  target: '_blank',
  rel: 'noopener noreferrer',
  onClick: handleClick,
};

const Footer = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <Row mt={4} jc="space-between">
          <T.H4 color="white" mb={4}>
            <Icon height={80} icon={'scoutStack'} color="white" />
          </T.H4>
        </Row>
        <S.Line />
        <Row inner>
          <Col w={[4, 4, 3]}>
            <T.H4 color="white" mb={3}>
              Get involved
            </T.H4>
            <T.LinkFooter
              {...linkProps}
              href="https://www.scouts.org.uk/volunteer/"
            >
              Volunteer with us
            </T.LinkFooter>
            <T.LinkFooter
              {...linkProps}
              href="https://www.scouts.org.uk/groups/"
            >
              Find a local group
            </T.LinkFooter>
            <T.LinkFooter
              {...linkProps}
              href="https://www.scouts.org.uk/about-us/jobs/"
            >
              Work with us
            </T.LinkFooter>
            <T.LinkFooter
              {...linkProps}
              href={
                'https://www.scouts.org.uk/volunteers/volunteer-experience/'
              }
            >
              Changes to Scouts
            </T.LinkFooter>
            <T.LinkFooter
              {...linkProps}
              href="https://scouts.enthuse.com/profile"
            >
              Donate to Scouts
            </T.LinkFooter>
            <T.LinkFooter {...linkProps} href="https://www.scouts.org.uk/por/">
              Read our policies
            </T.LinkFooter>
          </Col>
          <Col w={[4, 4, 3]} mtM={4}>
            <T.H5 color="white" mb={3}>
              Use our services
            </T.H5>
            <T.LinkFooter {...linkProps} href="https://shop.scouts.org.uk/">
              Our Scout Store
            </T.LinkFooter>
            <T.LinkFooter
              {...linkProps}
              href="https://www.scoutadventures.org.uk/"
            >
              Our Activity Centres
            </T.LinkFooter>
            <T.LinkFooter
              {...linkProps}
              href="https://www.unityinsuranceservices.co.uk/scout-insurance"
            >
              Unity Insurance Services
            </T.LinkFooter>
            <T.LinkFooter {...linkProps} href="https://scoutsbrand.org.uk/">
              Our Brand Centre
            </T.LinkFooter>
          </Col>
          <Col w={[4, 4, 3]} mtM={4}>
            <T.H5 color="white" mb={3}>
              Look wider
            </T.H5>
            <T.LinkFooter {...linkProps} href="https://www.scouts.scot/">
              Scouts Scotland
            </T.LinkFooter>
            <T.LinkFooter {...linkProps} href="https://scoutscymru.org.uk/">
              Scouts Cymru
            </T.LinkFooter>
            <T.LinkFooter {...linkProps} href="https://www.scoutsni.org/">
              Scouts NI
            </T.LinkFooter>
            <T.LinkFooter {...linkProps} href="https://www.scout.org/">
              World Scouting
            </T.LinkFooter>
          </Col>
          <Col w={[4, 4, 3]} mtM={4}>
            <T.H5 color="white" mb={3}>
              Contact us
            </T.H5>
            <T.LinkFooter
              {...linkProps}
              href="https://www.scouts.org.uk/contact-us/"
            >
              Chat with us
            </T.LinkFooter>
            <T.LinkFooter {...linkProps} href="mailto:support@scouts.org.uk">
              support@scouts.org.uk
            </T.LinkFooter>
            <T.LinkFooter {...linkProps} href="tel:0345 300 1818">
              0345 300 1818
            </T.LinkFooter>
            <T.LinkFooter
              {...linkProps}
              href={
                'https://www.google.com/maps/place/The+Scout+Association/@51.6509117,-0.0010144,17z/data=!4m8!1m2!2m1!1sThe+Scout+Association,+Gilwell+Park,+Chingford,+London,+England+E4+7QW!3m4!1s0x0:0x47e8e50c2a29f6d6!8m2!3d51.6501521!4d0.0025997'
              }
            >
              Find us
            </T.LinkFooter>
          </Col>
        </Row>
        <S.Line />
        <Row mt={4} mb={4} inner>
          <Col w={[4, 12, 9]}>
            <T.BodySM color="white" display="block">
              © Copyright The Scout Association {new Date().getFullYear()}. All
              Rights Reserved.
            </T.BodySM>
            <T.BodySM color="white" display="block">
              Charity numbers: 306101 (England and Wales) and SC038437
              (Scotland).
            </T.BodySM>
            <T.BodySM color="white" display="block">
              Registered address: The Scout Association, Gilwell Park,
              Chingford, London, E4 7QW
            </T.BodySM>
            <T.BodySM color="white" display="block">
              <T.LinkFooterSM
                {...linkProps}
                href={
                  'https://www.scouts.org.uk/about-us/policy/terms-conditions/'
                }
              >
                Terms and Conditions
              </T.LinkFooterSM>
              ,{' '}
              <T.LinkFooterSM
                {...linkProps}
                href={
                  'https://www.scouts.org.uk/about-us/policy/data-protection-policy/'
                }
              >
                Data Protection Policy
              </T.LinkFooterSM>
              ,{' '}
              <T.LinkFooterSM
                {...linkProps}
                href={
                  'https://www.scouts.org.uk/about-us/policy/cookie-policy/'
                }
              >
                Cookie Policy
              </T.LinkFooterSM>
              {', and '}
              <T.LinkFooterSM
                {...linkProps}
                href="https://www.scouts.org.uk/sitemap/"
              >
                Site Map
              </T.LinkFooterSM>
            </T.BodySM>
          </Col>
          <Col w={[0, 4, 3]}>
            <S.ImgWrapper
              src={InvestorsInPeople}
              alt="Investors in People logo"
            />
          </Col>
        </Row>
      </S.Container>
    </S.Wrapper>
  );
};

export default Footer;
