import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .ant-skeleton-element {
    display: block !important;
    width: 100% !important;
  }

  .custom-collapse-header {
    width: 100%;
  }

  .custom-collapse {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    padding: 0;
    padding-right: 0px;
    margin-right: ${({ theme: { spacings } }) => spacings[2]};
    padding: ${({ theme }) => theme.spacings[3]};
    display: flex;
    align-items: center;

    padding-right: 24px;
    svg {
      padding: 0;
      width: 24px;
      transition: 0.2s;
      /* transform: translate(8px, 16px); */
    }
  }
  .ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
    border: 1px dashed ${({ theme }) => theme.colors.gray};
  }

  .ant-collapse-content-box {
    background: ${({ theme }) => theme.colors.white};
  }

  .ant-collapse > .ant-collapse-item-active > .ant-collapse-header > svg {
    /* transform: translate(8px, 20px); */
  }

  .ant-collapse-expand-icon {
    position: relative !important;
    display: flex;
    height: 100%;
  }

  .ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    position: relative;
    display: flex;
    align-self: center;
    right: 8px;
    transform: translateY(0%);
    /* top: auto; */
  }
`;

export const InfoCard = styled.div`
  display: flex;
  min-height: 57px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.litestGray};
  padding: 0;
`;

export const BookmarkCard = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings[3]};
  align-items: center;
  padding-left: ${({ theme }) => (theme.media.mobile ? theme.spacings[2] : 0)};
`;

export const LoadMoreButton = styled.div`
  margin-top: ${({ theme, mt }) => (mt ? theme.spacings[mt] || mt : '42px')};
  display: flex;
  justify-content: flex-start;
  ${({ theme }) => theme.media.mobile} {
    justify-content: center;
  }
`;

export const TipWrapper = styled.div`
  padding: 12px 12px 12px 16px;
`;

export const ArrowRight = styled.button`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  width: 55px;
  position: absolute;
  z-index: 5000;
  height: 55px;
  top: 205px;
  left: 100%;
  padding-right: 0px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  :hover {
    background: ${({ theme }) => theme.colors.white};
  }
  ::before {
    display: none;
  }
  ${({ theme }) => theme.media.tablet} {
    left: 99.7%;
  }
  ${({ theme }) => theme.media.mobile} {
    top: 305px;
    left: 96%;
    padding-right: 12px;
  }
`;
export const ArrowLeft = styled.button`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  width: 55px;
  height: 55px;
  top: 180px;
  position: absolute;
  z-index: 5000;
  padding-left: 0px;
  transform: translateX(-50%);
  right: 24px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  :hover {
    background: ${({ theme }) => theme.colors.white};
  }
  ::before {
    display: none;
  }
  ${({ theme }) => theme.media.mobile} {
    top: 280px;

    left: -12px;
    padding-left: 22px;
  }
`;

export const ShowOnMobile = styled.div`
  display: none;
  ${({ theme }) => theme.media.mobile} {
    display: block;
  }
`;

export const HideOnMobile = styled.div`
  display: block;
  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 24px;
`;
export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.liteBlue};
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

export const SliderWrapper = styled.div`
  .slick-list > .slick-track {
    margin-left: 0;
  }
`;
